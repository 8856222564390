import { VariantProps, cva } from "class-variance-authority";
import { cn } from "../utils";
import { Slot } from "@radix-ui/react-slot";
import { forwardRef } from "react";

export const textVariants = cva("", {
  variants: {
    weight: {
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semi: "font-semibold",
      bold: "font-bold",
      inherit: "font-inherit",
    },
    size: {
      inherit: "text-inherit",
      xs: "text-xs leading-4",
      sm: "text-sm leading-5",
      base: "text-base",
      lg: "text-lg",
      xl: "text-xl",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
    },
    color: {
      content: "text-content",
      contrast: "text-contrast",
      muted: "text-content/50",
      success: "text-success",
      warning: "text-warning",
    },
  },
  defaultVariants: {
    weight: "inherit",
    size: "inherit",
    color: "content",
  },
});

export interface TextProps
  extends VariantProps<typeof textVariants>,
    Omit<React.HTMLAttributes<HTMLDivElement>, "color"> {
  asChild?: boolean;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span" | "dt" | "dd";
}

export const Text = forwardRef<HTMLDivElement, TextProps>(
  ({ as = "span", asChild, weight, color, size, className, ...props }: TextProps, ref) => {
    const Comp = asChild ? Slot : as;
    return <Comp ref={ref} className={cn(textVariants({ weight, color, size }), className)} {...props} />;
  },
);

Text.displayName = "Text";
